import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap flex-stack mb-6" }
const _hoisted_2 = { class: "fw-bolder my-2" }
const _hoisted_3 = { class: "fs-6 text-gray-400 fw-bold ms-1" }
const _hoisted_4 = { class: "d-flex my-2" }
const _hoisted_5 = { class: "row g-6 g-xl-9" }
const _hoisted_6 = { class: "row g-6 g-xl-9" }
const _hoisted_7 = { class: "col-xl-12" }
const _hoisted_8 = { class: "card-body border-top p-9" }
const _hoisted_9 = { class: "card-body border-top p-9" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardUser = _resolveComponent("CardUser")!
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_UserCU = _resolveComponent("UserCU")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _component_ChangePassword = _resolveComponent("ChangePassword")!
  const _component_Offices = _resolveComponent("Offices")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createTextVNode(_toDisplayString(_ctx.$t("user")) + " ", 1),
        _createElementVNode("span", _hoisted_3, "(" + _toDisplayString(_ctx.users.length) + ")", 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.create_user)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "btn btn-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.newUser && _ctx.newUser(...args)))
            }, _toDisplayString(_ctx.$t("btnNew")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.usersList, (cg) => {
        return (_openBlock(), _createElementBlock("div", {
          key: cg.id,
          class: "col-xl-4"
        }, [
          _createVNode(_component_CardUser, {
            "widget-classes": "card-xl-stretch mb-xl-8",
            color: "primary",
            item: cg,
            onProfile: _cache[1] || (_cache[1] = (id) => _ctx.$router.push(`/user/${id}/overview`)),
            onUpdate: _ctx.editUser,
            onDelete: _ctx.deleteUser,
            onChange_password: _ctx.updatePassword,
            onAssign_offices: _ctx.assignOffices
          }, null, 8, ["item", "onUpdate", "onDelete", "onChange_password", "onAssign_offices"])
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        (_ctx.usersList.length > 0)
          ? (_openBlock(), _createBlock(_component_el_pagination, {
              key: 0,
              currentPage: _ctx.page,
              "onUpdate:currentPage": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.page) = $event)),
              "page-sizes": [
          Math.round(_ctx.users.length),
          Math.round(_ctx.users.length / (_ctx.users.length / 2)),
          Math.round(_ctx.users.length / _ctx.users.length),
        ],
              "page-size": _ctx.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _ctx.users.length,
              "page-count": Math.round(_ctx.users.length / _ctx.pageSize),
              onSizeChange: _ctx.handleSizeChange,
              onCurrentChange: _ctx.setPage
            }, null, 8, ["currentPage", "page-sizes", "page-size", "total", "page-count", "onSizeChange", "onCurrentChange"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.typeEvent !== '')
      ? (_openBlock(), _createBlock(_component_el_dialog, {
          key: 0,
          modelValue: _ctx.dialogVisible,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.dialogVisible) = $event)),
          width: "70%",
          onClose: _ctx.resetModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_UserCU, {
              "type-event": _ctx.typeEvent,
              item: _ctx.element,
              onSuccess: _ctx.successOperation
            }, null, 8, ["type-event", "item", "onSuccess"])
          ]),
          _: 1
        }, 8, ["modelValue", "onClose"]))
      : _createCommentVNode("", true),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogPassword,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.dialogPassword) = $event)),
      width: "55%",
      onClose: _ctx.resetModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_ChangePassword, {
            item: _ctx.element,
            onSuccess: _cache[4] || (_cache[4] = ($event: any) => (_ctx.dialogPassword = false))
          }, null, 8, ["item"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.dialogOffices,
      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.dialogOffices) = $event)),
      width: "55%",
      onClose: _ctx.resetModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_Offices, {
            item: _ctx.element,
            onSuccess: _cache[6] || (_cache[6] = ($event: any) => (_ctx.dialogOffices = false))
          }, null, 8, ["item"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue", "onClose"])
  ], 64))
}