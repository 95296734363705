<template>
  <div class="card-xl-stretch mb-xl-8 card">
    <div class="card-body d-flex align-items-center pt-0 pb-0">
      <div class="d-flex flex-column flex-grow-1 py-2 py-lg-8 me-2">
        <a
          href="javascript:void(0)"
          class="fw-bolder text-dark fs-4 text-hover-primary"
          >{{ item.name }}</a
        ><span class="fw-bold text-muted fs-5"> {{ item.email }} </span>

        <div class="row pt-2">
          <div class="col-12">
            <div class="fs-7 text-muted">{{ $t("iborndate") }}</div>
            <div class="fs-6 fw-bolder">{{ item.born_date }}</div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12">
            <div class="fs-7 text-muted">{{ $t("icif") }}</div>
            <div class="fs-4 fw-bolder">{{ item.cif_nif }}</div>
          </div>
        </div>
        <div class="row pt-2">
          <div class="col-12">
            <div class="fs-7 text-muted">{{ $t("iphone") }}</div>
            <div class="fs-6 fw-bolder">{{ item.phone_number }}</div>
          </div>
        </div>
      </div>
      <div>
        <!--begin:Menu-->
        <div
          v-if="delete_user || update_user"
          class="ms-1 dropdown float-rigth align-self-start"
        >
          <button
            type="button"
            class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary dropdown-toggle float-end"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span class="svg-icon svg-icon-2">
              <inline-svg
                src="media/icons/duotone/Layout/Layout-4-blocks-2.svg"
              />
            </span>
          </button>
          <ul
            class="dropdown-menu menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold w-200px"
            aria-labelledby="dropdownMenuButton1"
          >
            <li v-if="update_user" class="menu-item px-3">
              <div
                class="dropdown-item menu-content fs-6 text-info fw-bolder px-3 py-4"
                @click="$emit('update', item)"
              >
                {{ $t("btnEdit") }}
              </div>
            </li>
            <li v-if="delete_user" class="menu-item px-3">
              <div
                class="dropdown-item menu-content fs-6 text-danger fw-bolder px-3 py-4"
                @click="$emit('delete', item.id)"
              >
                {{ $t("btnDelete") }}
              </div>
            </li>
            <li v-if="update_password" class="menu-item px-3">
              <div
                class="dropdown-item menu-content fs-6 text-warning fw-bolder px-3 py-4"
                @click="$emit('change_password', item.id)"
              >
                {{ $t("iupdatepass") }}
              </div>
            </li>
            <li v-if="assign_offices" class="menu-item px-3">
              <div
                class="dropdown-item menu-content fs-6 text-success fw-bolder px-3 py-4"
                @click="$emit('assign_offices', item)"
              >
                {{ $t("iassignoffices") }}
              </div>
            </li>
          </ul>
        </div>
        <!--end::Menu-->
        <img
          :src="item.avatar || '/media/defaults/avatar.png'"
          alt=""
          class="align-self-end h-100px w-100px mt-2 rounded"
        />
      </div>
    </div>
    <div class="d-flex flex-center flex-column">
      <button
        class="btn btn-sm btn-light-primary fw-bolder py-2 mx-2 px-3 mb-2"
        @click="$emit('profile', item.id)"
      >
        {{ $t("iprofile") }}
      </button>
    </div>
  </div>
</template>

<script>
import { hasPermission } from "@/core/helpers/functions";
import { defineComponent, ref } from "@vue/runtime-core";

export default defineComponent({
  name: "CardUser",
  props: {
    item: Object
  },
  setup() {
    return {
      delete_user: ref(hasPermission("users: delete")),
      update_user: ref(hasPermission("users: update")),
      update_password: ref(hasPermission("users: update password")),
      assign_offices: ref(hasPermission("users: assign offices"))
    };
  }
});
</script>
