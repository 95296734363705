
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CardUser from "@/components/cards/CardUser.vue";
import UserCU from "@/views/users/UsersCU.vue";
import ChangePassword from "./ChangePassword.vue";
import Offices from "./Offices.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useStore } from "vuex";
import { hasPermission } from "@/core/helpers/functions";
export default defineComponent({
  name: "ManagementUsers",
  components: {
    CardUser,
    UserCU,
    ChangePassword,
    Offices,
  },
  setup() {
    const store = useStore();
    // eslint-disable-next-line @typescript-eslint/ban-types
    const users = ref<Array<object>>([]);
    const typeEvent = ref("");
    const dialogVisible = ref(false);
    const dialogPassword = ref(false);
    const dialogOffices = ref(false);
    const element = ref({});
    const page = ref(1);
    const pageSize = ref(1);

    const getUsers = () => {
      ApiService.get("/api/users").then(({ data }) => {
        users.value = data;
        pageSize.value = users.value.length;
      });
    };

    const newUser = () => {
      typeEvent.value = "create";
      dialogVisible.value = true;
    };

    const editUser = (item) => {
      element.value = item;
      typeEvent.value = "update";
      dialogVisible.value = true;
    };

    const deleteUser = (id) => {
      Swal.fire({
        text: "Esta seguro que desea eliminar a este usuario",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
        customClass: {
          confirmButton: "btn fw-bold btn-light-danger",
          cancelButton: "btn fw-bold btn-light-primary",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("/api/users/" + id).then(() => {
            const i = users.value.findIndex(
              (e: Record<string, any>) => e.id === id
            );
            users.value.splice(i, 1);
          });
        }
      });
    };

    const updatePassword = (user_id) => {
      dialogPassword.value = true;
      element.value = { user_id: user_id };
    };

    const assignOffices = (user) => {
      dialogOffices.value = true;
      element.value = user;
    };

    const successOperation = (response) => {
      const index = users.value.findIndex(
        (el: Record<string, any>) => el.id === response.id
      );
      if (index >= 0) {
        users.value[index] = response;
      } else {
        users.value.push(response);
      }
      dialogVisible.value = false;
    };

    const resetModal = () => {
      typeEvent.value = "";
      element.value = {};
    };

    const handleSizeChange = (val) => {
      page.value = 1;
      pageSize.value = val;
    };

    const setPage = (val) => {
      page.value = val;
    };

    const usersList = computed(() => {
      if (store.getters.searchLocal === "") {
        return (
          users.value.slice(
            pageSize.value * page.value - pageSize.value,
            pageSize.value * page.value
          ) || []
        );
      } else {
        const result = users.value.filter(
          (ele: Record<string, any>) =>
            ele.name
              .toLowerCase()
              .includes(store.getters.searchLocal.toLowerCase()) ||
            ele.email
              .toLowerCase()
              .includes(store.getters.searchLocal.toLowerCase())
        );
        return (
          result.slice(
            pageSize.value * page.value - pageSize.value,
            pageSize.value * page.value
          ) || []
        );
      }
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("usersIndex", ["user"], true);
      getUsers();
    });

    return {
      newUser,
      editUser,
      deleteUser,
      successOperation,
      resetModal,
      handleSizeChange,
      setPage,
      create_user: ref(hasPermission("users: create")),
      users,
      usersList,
      typeEvent,
      dialogVisible,
      dialogPassword,
      updatePassword,
      dialogOffices,
      assignOffices,
      element,
      page,
      pageSize,
    };
  },
});
